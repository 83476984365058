<template>
  <div>
    <!--
    <div class="parallax">
    </div>
    -->
    <!-- CAROUSEL-->
    <!-- <b-row>
      <b-col cols="12" >
        <div>
          
          <b-carousel indicators :interval="5000" controls >
            <b-carousel-slide                
                v-if="resources!=null"
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row> -->

    <h1 class="titles mt-5"> <span style="color: #d82125">Información Teotihuacán</span> </h1>
      <!-- <span style="color: #d02175">n</span>
      <span style="color: #f6c921">f</span>
      <span style="color: #d02175">o</span>
      <span style="color: #4d944a">r</span>
      <span style="color: #07aaba">m</span>
      <span style="color: #d82125">a</span>
      <span style="color: #d02175">c</span>
      <span style="color: #f6c921">i</span>
      <span style="color: #d02175">ó</span>
      <span style="color: #07aaba">n </span>  -->
      

    <!-- 
        #d82125   T
        #d02175   E
        #f6c921   P
        #d02175   E
        #4d944a   T
        #07aaba   L
        #d82125   A
        #d02175   O
        #f6c921   X
        #d02175   T
        #07aaba   O
        #4d944a   C
    -->
    
    <br>
    <br>


    <b-row  align-h="center" class="mt-4" >
      <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
        
          <b-container  @click="$router.push('/hoteles')" style="cursor:pointer;">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/hotelp.jpg" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
           <h2 class="titles "> <span style="color: #d82125">¿ Dónde hospedarse ?</span> </h2>
           <br><br><br><br>
        </b-col>

        <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container @click="$router.push('/restaurantes')" style="cursor:pointer;">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/restaurantep.jpg" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
          <h2 class="titles "> <span style="color: #d82125">¿ Dónde comer ?</span> </h2>
          <br><br><br><br>
        </b-col>


          <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container @click="$router.push('/atracciones')" style="cursor:pointer;">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/globosp.jpg" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
           <h2 class="titles "> <span style="color: #d82125">Atracciones para divertirse</span> </h2>         
           <br><br><br><br>
        </b-col>

         <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container @click="$router.push('/temazcales')" style="cursor:pointer;">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/temazcalp.jpg" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
           <h2 class="titles "> <span style="color: #d82125">Experiencia de Salud y Descanso</span> </h2>         
          <br><br><br><br>
        </b-col>
       


  <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container @click="$router.push('/fiestas')" style="cursor:pointer;">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/fiestasp.jpg" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
           <h2 class="titles "> <span style="color: #d82125">Fiestas para disfrutar</span> </h2>         
            <br><br><br><br>
        </b-col>

   <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container @click="$router.push('/danzantes')" style="cursor:pointer;">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/danzap.jpg" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
           <h2 class="titles "> <span style="color: #d82125">Juego de Pelota y Danza Prehispánica</span> </h2>         
           <br><br><br><br>
        </b-col>


 <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container @click="$router.push('/monumentos')" style="cursor:pointer;">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/monumentop.jpg" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
          <h2 class="titles "> <span style="color: #d82125">Monumentos Hístoricos</span> </h2>         
          <br><br><br><br>
        </b-col>

 <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
   <b-container @click="$router.push('/zona')" style="cursor:pointer;">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/teotihuacanp.jpg" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
            <h2 class="titles "> <span style="color: #d82125">Zona Arquelógica</span> </h2>         
            <br><br><br><br>
        </b-col>

  <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container @click="$router.push('/artesanias')" style="cursor:pointer;">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/artesaniap.jpg" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
            <h2 class="titles "> <span style="color: #d82125">Venta de Artesanías</span> </h2>         
            <br><br><br><br>
        </b-col>





    </b-row>
<br>



    <br>
    <br>

    
  </div>
</template>
<script>


  export default {
    components: {
    },
    mounted: function(){
      
    },
    computed: {
      styleIconBall: function(){
        var style = this.iconBall;
        if(this.width <= 768){
          style=this.iconBallSm;
        }
        return style;
      },
      iconBall: function(){
        return " display: block; width: 100%; height: 100%; align-items: center; " +
        "background: linear-gradient(90deg, rgba(255,31,31,1) 0%, rgba(248,0,0,1) 100%);" +
        "border-radius: 50%; " +
        "box-shadow: inset -25px -25px 40px rgba(10,10,30,.4); " +
        "background-image: -webkit-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -moz-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -o-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -ms-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "position:absolute; left: 0px; top:0px; text-align:center; ";
      }
    },
    data() {
      return {
        resources:null,
        showPredial:false,
        showFact:false,
        showGobinet:false
      }
    }
    
  }
</script>

<style>
.flipbook .viewport {
    width: 90vw;
    height: 90vh;
}
.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}

</style>